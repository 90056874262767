import request from '@/utils/request'




export function apiPost(url,params) {
  return request(
    url,params
    );
}




export function apipost(url,params) {
  return request(
    url,params
    );
}



export function apiGet(url,params) {
  return request(
    url,params
    );
}





export function getInformationArray(params={}) {
  return request(
    '/information/index',
    params
    );
}




export function getCensusData() {
  return request(
    '/index/census',
    {}
    );
}
export function getDeviceLists(params={}) {
  return request(
    '/device/index',
    params
    );
}

export function getPlaceLists(params={}) {
  return request(
    '/place/index',
    params
    );
}

export function getAdminList() {
  return request(
    '/auth/admin',
    {}
    );
}


export function updateAdmin(params) {
  return request(
    '/auth/update',
    params
    );
}




 
  export function initGroupTrees() {
    return request(
      '/auth/group/trees',
      {}
      );
  }
  


export function getRules() {
  return request(
    '/auth/rules',
    {}
    );
}


export function getRuleRoutes() {
  return request(
    '/auth/rule/route',
    {}
    );
}



export function getRuleTrees(params) {
  return request(
    '/auth/rule/tree',
    params
    );
}






export function postXXX(params) {
  return request('/api/post', {
    method: 'POST',
    params: params,
  });
}
