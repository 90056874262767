import { createApp } from 'vue'
import 'element-plus/dist/index.css'

import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//import locale from 'element-plus/lib/locale/lang/zh-cn'


import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)




app.use(store)
app.use(router)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  app.use(ElementPlus,{ size: 'mini'})


app.mount('#app')

//createApp(App).use(store).use(router).mount('#app')
