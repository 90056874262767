import service from '@/utils/http'

const Request = (url, params = {}) => {
    //let method = options.method || 'post';
    //let params = options.params || {};

    let method ='post';
    
    if (method === 'get' || method === 'GET') {
      return new Promise((resolve, reject) => {
        service
          .get(url, {
            params: params,
          })
          .then(res => {
            if (res && res.data) {
              resolve(res.data);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        service
          .post(url, params)
          .then(res => {
           // resolve(res.data);

            if (res && res.data) {
              resolve(res.data);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  };
  



  export default Request;